export const teamWorking = [

  // Social Media and Marketing
  {
    name: "Mr. Ansh Choudhary",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Mr. Asad Khan",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Mr. Harshit Gupta",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Mr. Mujtaba Haider",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Mr. Preetam Ray",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Mr. Shikhar Singh",
    title: "Social Media & Marketing",
    img: "",
  },

  // Outreach Department
  {
    name: "Mr. Adarsh Prakash Singh",
    title: "Outreach",
    img: "",
  },
  {
    name: "Ms. Ananya Chaturvedi",
    title: "Outreach",
    img: "",
  },
  {
    name: "Mr. Divyanshu Chaubey",
    title: "Outreach",
    img: "",
  },
  {
    name: "Mr. Gaurav Singh",
    title: "Outreach",
    img: "",
  },
  {
    name: "Mr. Harshit Agrawal",
    title: "Outreach",
    img: "",
  },
  {
    name: "Mr. Ritesh Pandey",
    title: "Outreach",
    img: "",
  },

  // Technical Operations
  {
    name: "Mr. Anurag Singh",
    title: "Technical Operation",
    img: "",
  },
  {
    name: "Ms. Bhumi Bhardwaj",
    title: "Technical Operation",
    img: "",
  },
  {
    name: "Ms. Radhika Chauhan",
    title: "Technical Operation",
    img: "",
  },
  {
    name: "Mr. Ravi Choudhary",
    title: "Technical Operation",
    img: "",
  },
  {
    name: "Mr. Shubham Chaudhary",
    title: "Technical Operation",
    img: "",
  },
  {
    name: "Mr. Vineet Pathak",
    title: "Technical Operation",
    img: "",
  },

  // Content & Documentation Department
  {
    name: "Mr. Aman Upadhyay",
    title: "Content & Documentation",
    img: "",
  },
  {
    name: "Ms. Khushbu Kumari",
    title: "Content & Documentation",
    img: "",
  },
  {
    name: "Mr. Garvit Saini",
    title: "Content & Documentation",
    img: "",
  },
  {
    name: "Mr. Parv Chaudhary",
    title: "Content & Documentation",
    img: "",
  },
  {
    name: "Mr. Priyanshu Jha",
    title: "Content & Documentation",
    img: "",
  },
  {
    name: "Ms. Shivani Rai",
    title: "Content & Documentation",
    img: "",
  },

  // Planning & Strategy Department
  {
    name: "Mr. Aradhya Maheshwari",
    title: "Planning & Strategy",
    img: "",
  },
  {
    name: "Mr. Hariom Buchasia",
    title: "Planning & Strategy",
    img: "",
  },
  {
    name: "Mr. Kanishk Sharma",
    title: "Planning & Strategy",
    img: "",
  },
  {
    name: "Ms. Nazia Bano",
    title: "Planning & Strategy",
    img: "",
  },
  {
    name: "Ms. Saumya",
    title: "Planning & Strategy",
    img: "",
  },
  {
    name: "Mr. Satyam Kumar Mishra",
    title: "Planning & Strategy",
    img: "",
  },

];
